img {
  max-width: 100%;
}

a {
  color: inherit;
}

a:hover {
  color: inherit;
  text-decoration: none;
}
