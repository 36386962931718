.grtyoutube-popup {
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:99999;
}

.grtyoutube-popup-content {
	margin-top:150px;
	width:95%;
	max-width:850px;
	margin-left:auto;
	margin-right:auto;
	position:relative;
}

.grtyoutube-popup-close {
	position:absolute;
	top:-30px;
	right:0;
	color:#FFF;
	font-size:25px;
	width:17px;
	height:17px;
	cursor:pointer;
}

.grtyoutube-iframe {
	width:100%;
	height:480px;
}
@media (max-width:767px) {
	.grtyoutube-iframe {
		width:100%;
		height:350px;
	}
}

.grtyoutube-dark-theme{
	background: rgba(0,0,0, 0.85);
}

.grtyoutube-dark-theme .grtyoutube-popup-content {
	-webkit-box-shadow: 0px 0px 8px 0px rgba(255,255,255,0.4);
	-moz-box-shadow: 0px 0px 8px 0px rgba(255,255,255,0.4);
	box-shadow: 0px 0px 8px 0px rgba(255,255,255,0.4);
}

.grtyoutube-dark-theme .grtyoutube-popup-close {
	background: url('icon-close-white.png') no-repeat;
}

.grtyoutube-light-theme {
	background: rgba(255,255,255, 0.85);
}

.grtyoutube-light-theme .grtyoutube-popup-content {
	-webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.4);
	-moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.4);
	box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.4);
}

.grtyoutube-light-theme .grtyoutube-popup-close {
	background: url('icon-close-black.png') no-repeat;
}


