.brand--color {
  color: $brand-color;
}

.info--color {
  color: $info-color;
}

.product--color {
  color: $product-color;
}

.details--color {
  color: $details-color;
}

.business--color {
  color: $business-color;
}

.userinput--color {
  color: $userinput-color;
}

.red--color {
  color: $red-color;
}

.yellow--color {
  color: $yellow-color;
}

.white--color {
  color: $white-color;
}

// .lightgreen--color {
//   color: $lightgreen-color;
// }

// .blue--color {
//   color: $blue-color;
// }