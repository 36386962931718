﻿@mixin placeholder {
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
  ::-webkit-input-placeholder {
    @content;
  }
}
