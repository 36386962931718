﻿$jungle-green:#36CCA5;
$sapphire-blue:#62B4F9;
$gold-yellow:#FF8800;
$crimson-red:#FF5252;
$electric-purple:#B604B6;
$azure-blue:#63B4F9;
$azure-yellow:#FFCA65;
$azure-red:#F86156;

$brand-color:$jungle-green;
$info-color:$sapphire-blue;
$product-color:$gold-yellow;
$business-color:$electric-purple;
$details-color:$crimson-red;
$userinput-color:$azure-blue;
$red-color:$azure-red;
$yellow-color:$azure-yellow;

$white-color:#fff;

$component-radius:31px;

$business-primary-font:'Montserrat',
sans-serif;
$business-secondary-font:'Comfortaa',
cursive;